import React from "react";
import "./SkillSection.css";
import SkillContentModel from "../../models/skill_content";
import SkillContent from "./components/SkillContent";

const skillContents = [
  new SkillContentModel("Mobile Developer", "mobile-android", [
    "Programming Languages: Dart, Kotlin, Swift",
    "Mobile App Development (Flutter, Jetpack Compose, SwiftUI)",
    "Mobile App Testing and Debugging",
    "Mobile App Security",
    "Mobile App Monitoring and Performance Optimization",
    "Mobile App Deployment",
    "Mobile App Maintenance and Support",
  ]),
  new SkillContentModel("Backend Developer", "arrow", [
    "Framework/Library: NodeJS, ExpressJS, NestJS",
    "Programming Languages: Javascript, TypeScript",
    "Database: MongoDB, PostgreSQL, Firestore",
    "Knowledge of Firebase, Google Cloud Platform",
    "Knowledge of Web3, Blockchain",
  ]),
  new SkillContentModel("Others", "pen", [
    "SOLID principles",
    "Design Patterns",
    "Agile development methodology",
    "Version control: Git (Github, Gitlab)",
    "Tasks control: Notion, Jira, Trello",
    "English capability",
    "UI/UX Design",
  ]),
];

const SkillSection = () => {
  return (
    <section className="skill section" id="skill">
      <h1 className="section__title">Skills</h1>
      <span className="section__subtitle">My Technical</span>

      <div className="skill__container container grid">
        {skillContents.map((item, index) => (
          <SkillContent key={index} content={item} />
        ))}
      </div>
    </section>
  );
};

export default SkillSection;
