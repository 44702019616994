import React from "react";
import "./ExperienceSection.css";

import ExperienceItemModel from "../../models/experience_item";
import ExperienceItem from "./components/ExperienceItem";

const experienceItems = [
  new ExperienceItemModel(
    "NOTT Innovative Technology",
    "Mobile Engineer",
    "01/2024 - Present",
    "https://nott.ai/"
  ),
  new ExperienceItemModel(
    "Savvycom",
    "Mobile Engineer",
    "02/2023 - 12/2023",
    "https://savvycom.vn/"
  ),
  new ExperienceItemModel(
    "CHT LAB",
    "Software Developer",
    "07/2022 - 01/2023",
    "https://chtlab.io/"
  ),
  new ExperienceItemModel(
    "TECHFUSION",
    "Freelance Developer",
    "01/2022 - 01/2024",
    "https://www.techfusion.dev/"
  ),
  new ExperienceItemModel(
    "VCCORP CORPORATION",
    "Mobile Developer",
    "01/2021 - 12/2021",
    "https://vccorp.vn/"
  ),
  new ExperienceItemModel(
    "VNU-UNIVERSITY OF ENGINEERING AND TECHNOLOGY",
    "Bachelor of Science - Valedictorian",
    "09/2018 - 12/2022",
    "https://uet.vnu.edu.vn/"
  ),
];

const Experience = () => {
  return (
    <section className="experience section" id="experience">
      <h1 className="section__title">Experiences</h1>
      <span className="section__subtitle">My Personal Journal</span>

      <div className="experience__container container grid">
        <div className="experience__content">
          {experienceItems.map((item, index) => (
            <ExperienceItem key={index} item={item} isOdd={index % 2 === 1} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Experience;
