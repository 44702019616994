import React from "react";

const ExperienceItem = ({ item, isOdd }) => {
  return (
    <div className="experience__item">
      {isOdd ? <div /> : null}

      {isOdd ? (
        <div>
          <span className="experience__item-indicator"></span>
          <span className="experience__item-line"></span>
        </div>
      ) : null}

      <div>
        <a
          href={item.link}
          target="_blank"
          rel="noreferrer"
          className="experience__item-title"
        >
          {item.title}
        </a>
        <span className="experience__item-subtitle">{item.subtitle}</span>
        <div className="experience__item-date">
          <i className="uil uil-calendar-alt"></i> {item.date}
        </div>
      </div>

      {!isOdd ? (
        <div>
          <span className="experience__item-indicator"></span>
          <span className="experience__item-line"></span>
        </div>
      ) : null}
    </div>
  );
};

export default ExperienceItem;
