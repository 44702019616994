import React, { useState } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import CarCheckApp from "../../assets/carcheck_app.png";
import InsixApp from "../../assets/insix_app.png";
import CrushItApp from "../../assets/crushit_app.png";
import MwcApp from "../../assets/mwc_app.png";
import VivanaApp from "../../assets/vivana_app.png";
import CapreloApp from "../../assets/caprelo_app.png";
import PrasacApp from "../../assets/prasac_app.png";
import NottApp from "../../assets/nott_app.png";

import "./PortfolioSection.css";

import PortfolioItemModel from "../../models/portfolio_item";
import PortfolioItem from "./components/PortfolioItem";
import PortfolioSliderOverlay from "./components/PortfolioSliderOverlay";

export const portfolioItems = [
  new PortfolioItemModel(
    "NOTT",
    "",
    NottApp,
    "https://apps.apple.com/us/app/nott/id6499422929",
    "https://play.google.com/store/apps/details?id=ai.nott&hl=en",
    null
  ),
  new PortfolioItemModel(
    "KB PRASAC Mobile",
    "",
    PrasacApp,
    "https://apps.apple.com/us/app/kb-prasac-mobile/id971426760",
    "https://play.google.com/store/apps/details?id=com.prasac.ibanking.prasacmbanking&hl=en",
    null
  ),
  new PortfolioItemModel(
    "CapRelo Companion",
    "",
    CapreloApp,
    "https://apps.apple.com/us/app/caprelo-companion/id1604853270",
    "https://play.google.com/store/apps/details?id=com.onerivet.caprelo.droid&hl=en",
    null
  ),
  new PortfolioItemModel(
    "INSIX",
    "",
    InsixApp,
    "https://apps.apple.com/us/app/in-six/id1659870384",
    "https://play.google.com/store/apps/details?id=dev.techfusion.insix&hl=en",
    null
  ),
  new PortfolioItemModel(
    "CRUSH IT",
    "",
    CrushItApp,
    "https://apps.apple.com/us/app/crush-it-by-senada-greca/id1559681689",
    "https://play.google.com/store/apps/details?id=dev.techfusion.senadagreca",
    null
  ),
  new PortfolioItemModel(
    "MWC STORE",
    "",
    MwcApp,
    "https://apps.apple.com/vn/app/mwc-com-vn/id1618163573",
    "https://play.google.com/store/apps/details?id=vn.com.mwc.app",
    null
  ),
  new PortfolioItemModel(
    "VIVANA",
    "",
    VivanaApp,
    "https://apps.apple.com/vn/app/vivana/id6443502255",
    "https://play.google.com/store/apps/details?id=net.vivana.application&hl=vi&gl=US",
    null
  ),
  new PortfolioItemModel("CARCHECK", "", CarCheckApp, null, null, null),
];

const PortfolioSection = () => {
  const [visibleSlider, setVisibleSlider] = useState(false);
  const [clickedItemIndex, setClickedItemIndex] = useState(0);

  return (
    <section className="portfolio section" id="portfolio">
      <h1 className="section__title">Portfolio</h1>
      <span className="section__subtitle">Most Recent Works</span>

      <div className="portfolio__container container grid">
        {portfolioItems.map((item, index) => (
          <PortfolioItem
            key={index}
            item={item}
            onImageClicked={() => {
              disableBodyScroll(document);
              setClickedItemIndex(index);
              setVisibleSlider(true);
            }}
          />
        ))}
      </div>

      {visibleSlider && (
        <PortfolioSliderOverlay
          items={portfolioItems}
          initialSlide={clickedItemIndex}
          onClose={() => {
            enableBodyScroll(document);
            setVisibleSlider(false);
          }}
        />
      )}
    </section>
  );
};

export default PortfolioSection;
